var x = require('react-dom');

var createPortal = x.createPortal,
    findDOMNode = x.findDOMNode,
    hydrate = x.hydrate,
    render = x.render,
    unstable_renderSubtreeIntoContainer = x.unstable_renderSubtreeIntoContainer,
    unmountComponentAtNode = x.unmountComponentAtNode,
    unstable_createPortal = x.unstable_createPortal,
    unstable_batchedUpdates = x.unstable_batchedUpdates,
    flushSync = x.flushSync,
    __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED = x.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
var __esModule = true;
export { __esModule, x as default, createPortal, findDOMNode, hydrate, render, unstable_renderSubtreeIntoContainer, unmountComponentAtNode, unstable_createPortal, unstable_batchedUpdates, flushSync, __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED };